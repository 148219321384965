.pages_exhibitions-container {
	position: relative;
	width: 100vw;
	max-width: $maxWidth;
	margin: auto;
	padding: 0;
	color: var(--foregroundColor);
	background: var(--backgroundColor);
	overflow-y: auto;

	main {
		position: relative;
		margin-top: 15vh;
		#top {
			position: absolute;
			top: 0;
			height: 6vh;
			color: var(--backgroundColor);
		}

		.btn-top {
			margin-top: 1em;
		}

		.page-links {
			@include font-size(20px, 28px);
			display: flex;
			width: 100%;
			padding: 0 0.5em;
			justify-content: space-between;
			align-items: center;
			margin-top: 2em;
			// gap: 1em;

			a {
				display: flex;
				min-width: 25vw;
				justify-content: center;

				svg {
					margin: 0;
				}
			}
		}
	}
}
