.flip-horizontal-bottom {
	-webkit-animation: flip-horizontal-bottom 0.4s
		cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
	animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
		both;
}

/**
* ----------------------------------------
* animation flip-horizontal-bottom
* ----------------------------------------
*/

@keyframes flip-horizontal-bottom {
	0% {
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
	}
	100% {
		-webkit-transform: rotateX(-360deg);
		transform: rotateX(-360deg);
	}
}

// SLIDE TOP
.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}
@keyframes slide-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}
}
