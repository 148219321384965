.exhibitions-title{
	
	h3{
		line-height: 1.2em;
		margin-left: 2em;
		margin-right: 2em;
		text-align: center;
	}
}

.components_exhibitions-container {
	position: relative;
	display: block;
	margin-top: 1em;
	margin-bottom: 4em;
	padding: 0.3em;

	h2 {
		margin-top: 1em;
		margin-bottom: 1em;
	}
}
