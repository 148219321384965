.pages-home_container {
	position: relative;
	width: 100vw;
	max-width: $maxWidth;
	margin: auto;
	padding: 0;
	color: var(--foregroundColor);
	background: var(--backgroundColor);
	overflow-y: auto;

	.home-banner-container {
		position: relative;
		display: block;
		width: 100%;
		min-height: 100vh;
		margin: auto;
		margin-bottom: 3em;
		padding-bottom: 3em;

		#top {
			position: absolute;
			top: 0;
			height: 6vh;
			color: var(--backgroundColor);
		}

		.list-container {
			width: 100%;
			display: flex;
			justify-content: center;
			align-self: start;
			flex-wrap: wrap;
			// border: 1px solid red;
		}

		.btn-top {
			margin-top: 1em;
		}

		.home-banner {
			position: absolute;
			display: flex;
			align-items: flex-end;
			// justify-content: center;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			padding: 0.8em;

			h3 {
				@include font-size(15px, 22px);
				font-family: 'Roboto', Times, serif;
				font-weight: 100;
				color: white;
				margin-left: 1em;
			}
		}
	}
	h3{
		color: var(--foregroundColor);
		margin-top: 1em;
		margin-bottom: 1em;
		text-align: center;
	}
	.player-wrapper{
		position: relative;
		width: 95%;
		margin: auto;
		margin-bottom: 4em;
		padding-top: 56.25%;
		pointer-events: none;
		border: 2px solid black;
		
		.react-player {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

// FOR DESKTOPS
@include mQ(760px) {
	.pages-home_container {
		.player-wrapper{
			box-shadow: var(--shadowSM);
		}
	}
}

