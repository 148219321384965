.about-title{
	h3{
		text-align: center;
		margin-bottom: 2em;
	}
}

.component_about-container {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 4em;

	.about-content {
		position: relative;
		display: block;
		width: 100%;
		margin-top: 0.5em;
		padding: 0.3em;
		// border: 1px solid red;

		p {
			@include font-size(14px, 18px);

			span {
				font-weight: 400;
			}
		}
	}
	.about-img {
		width: 100%;
		margin-top: 0.5em;
		padding: 1em;
		// border: 1px solid red;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			box-shadow: var(--shadowSM);
		}
	}

	.about_links-container {
		display: flex;
		flex-direction: column;
		
		h3 {
			@include font-size(16px, 20px);
			margin-bottom: 1em;
			text-align: left;
		}

		.links{
			padding: 0 1em;
		}

		a.about-link {
			@include font-size(15px, 19px);
			color: var(--greyColor);
			text-decoration: none;
			// margin: 0.5em 1.2em;
			font-weight: 300;
			letter-spacing: 0.04em;
			line-height: 1.2em;
			cursor: pointer;

			&:hover,
			:focus {
				color: var(--mainColor);
				transform: scale(1.02);
			}
		}
	}
}

img {
	display: flex;
	margin: 2em auto;
	width: 95%;
	max-width: 700px;
	height: 100%;
	object-fit: cover;
	box-shadow: var(--shadowSM);
}

// FOR DESKTOPS
@include mQ(768px) {
	.component_about-container {
		.about-content {
			width: 50%;
			padding: 1em;
		}
		.about-img {
			width: 50%;
		}
	}
}
