.components_menu {
	font-family: 'Roboto', sans-serif;
	position: relative;
	display: block;
	width: 600px;
	max-width: 95%;
	max-height: 100%;
	margin: auto;
	background-color: var(--backgroundColor);
	border-radius: 0.3em;
	border: 1px solid $ExtraLight;
	box-shadow: 3px 3px 6px $ExtraLight, -1px -1px 6px $ExtraLight;
	overflow: hidden;
	z-index: 20;

	h2 {
		display: block;
		color: var(--greyColor);
		margin: 0.5em;
		font-weight: 400;
	}

	.links-container {
		margin-bottom: 1.2em;
		span,
		a {
			@include font-size(28px, 50px);
			display: block;
			width: 85%;
			margin: 0.4em auto;
			text-align: center;
			font-weight: 600;
			color: var(--foregroundColor);
			border-radius: 0.5em;
			letter-spacing: 0.05em;
			cursor: pointer;
			transition: color 0.5s ease;

			&:hover,
			:focus {
				color: var(--mainColor);
			}
		}

		span.active {
			color: var(--backgroundColor);
			background-color: var(--mainColor);
			cursor: default;
		}
	}

	.links-container-policy {
		span {
			@include font-size(18px, 28px);
			display: block;
			width: 85%;
			margin: 0.5em auto;
			text-align: center;
			color: var(--greyColor);
			border-radius: 0.5em;
			cursor: pointer;
			transition: color 0.5s ease;

			&:hover,
			:focus {
				color: var(--foregroundColor);
			}
		}

		span.active {
			color: var(--backgroundColor);
			background-color: var(--mainColor);
		}
	}

	.language-container {
		display: flex;
		width: 85%;
		justify-content: center;
		align-items: center;
		color: var(--foregroundColor);
		margin: 1em auto;
		margin-top: 1.5em;
		padding: 0.3em;
		border: 2px solid var(--foregroundColor);
		border-radius: 1em;

		p {
			@include font-size(16px, 26px);
			margin: 0 0.5em;
			color: var(--greyColor);
			background-color: transparent;
		}
	}

	.icons-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: 85%;
		margin: 1em auto;
		margin-bottom: 1.5em;
		padding: 0.3em;
		border: 2px solid var(--foregroundColor);
		border-radius: 1em;

		button {
			margin-right: 0.5em;
		}

		a {
			@include font-size(19px, 28px);
			text-decoration: none;
			font-weight: 500;
			margin-left: 1em;
			color: var(--greyColor);
			transition: color 0.5s ease;

			&:hover,
			:focus {
				color: var(--mainColor);
			}
		}
	}
}
