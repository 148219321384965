@import '../../styles/styles.scss';

.form-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 4rem 0;
}

.form-card {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	width: 90%;
	padding: 1.5rem 2rem;
	max-width: 600px;
	border-radius: 20px;
}

.form-container .form-card h3 {
	@include font-size(20px, 28px);
	color: $mainColor;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 1.5rem;
	z-index: 100;
}

form input,
form textarea {
	border: 0;
	outline: none;
	margin: 10px 0;
	padding: 20px;
	background-color: #f5f5f5;
	font-size: 16px;
}

form button {
	padding: 15px;
	background-color: $mainColor;
	color: $Light;
	font-size: 18px;
	width: 150px;
	margin: 20px auto 0;
	border: 0;
	border-radius: 30px;
	outline: none;
	cursor: pointer;
}
