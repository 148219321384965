.component_header-line {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 5vh;
	padding-left: 0.3em;
	padding-right: 0.3em;

	h3 {
		height: 4vh;
	}

	.line-items {
		display: flex;
		height: 5vh;
		z-index: 1000;

		.custom-select {
			// @include font-size(14px, 24px);
			color: var(--lightColor);
			margin: 0.5em 0;
			height: 1.5em;
			background: var(--mainColor);
			outline: none;
			cursor: pointer;
			border: 2px solid var(--lightColor);
			border-radius: 0.5em;
			// box-shadow: 3px 3px 6px $ExtraLight, -1px -1px 6px $ExtraLight;
		}
	}
}

// Desktop Size
@media only screen and (min-width: $maxWidth) {
	.component_header-line {
		height: 6vh;

		.line-items {
			.custom-select {
				@include font-size(14px, 24px);
				margin: 0;
				border: none;
				box-shadow: none;
			}
		}
	}
}
