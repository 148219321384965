.buttons_container {
	display: flex;
	width: 100%;
	.btn {
		margin: auto;
	}
}

.btn {
	@include font-size(12px, 22px);
	text-decoration: none;
	padding: 0.4em 1.5em;
	color: var(--backgroundColor);
	background: var(--foregroundColor);
	border: none;
	border-radius: 3px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
	transition: all 1s ease;
	cursor: pointer;

	&:hover,
	:focus {
		background-image: linear-gradient(
			45deg,
			var(--mainColor),
			var(--darkColor)
		);
		transform: scale(1.1);
	}
}

.btn__main {
	@include button-bg-ol($mainColor, $Dark);
	background: transparent;
	border: 2px solid $mainDark;
	color: $mainDark;
	line-height: calc(1vw + 1.9rem);
	margin: 1.5em 0.5em;
	z-index: 10;
}

.btn-small {
	@include font-size(12px, 16px);
	padding: 0.3em 1.3em;
}

.btn-language {
	@include font-size(16px, 26px);
	margin: 0 0.5em;
	color: var(--foregroundColor);
	border: none;
	background-color: transparent;
	cursor: pointer;

	&:hover,
	:focus {
		color: var(--mainColor);
		transform: scale(1.1);
	}
}

.btn__social {
	@include font-size(20px, 26px);
	color: var(--backgroundColor);
	background: var(--foregroundColor);
	line-height: 1.5em;
	padding: 0.2em 0.8em;
	text-decoration: none;
	display: flex;
	justify-content: space-around;
	align-items: center;

	svg {
		@include size(20px, 26px, 1, 1);
		margin-right: 0.5em;
		path {
			fill: $Light;
		}
	}
}

.btn__facebook {
	background-color: $Facebook;
}

.btn__whatsapp {
	background-color: $Whatsapp;
}

.btn__twitter {
	background-color: $Twitter;
}

.btn__instagram {
	background-color: $Instagram;
}

.btn__telegram {
	background-color: $Telegram;
}

@media screen and (max-width: 350px) {
	.btn {
		font-size: 14px;
		padding: 0.2em 0.6em;
	}
}
