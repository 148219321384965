.pages-container {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100vw;
	max-width: $maxWidth;
	min-height: 100vh;
	margin: auto;
	padding: 0;
	color: var(--foregroundColor);
	background: var(--darkColor);
	overflow-y: auto;
	// overflow: hidden;

	header {
		@include font-size(14px, 18px);
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 5vh;
		line-height: 5vh;
		margin: 0;
		color: var(--lightColor);
		background-color: var(--background);
		text-align: center;
		z-index: 10;
	}
	
	main {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		min-height: 100vh;
		margin: auto;
		margin-top: 12vh;
		margin-bottom:0;
		padding: 0;
		// padding-bottom: 3em;
		color: var(--foregroundColor);
		background-color: var(--backgroundColor);

		h1.page-title {
			width: 100%;
			text-align: center;
			color: var(--foregroundColor);
			margin: auto;
			margin-top: 0.5em;
			margin-bottom: 1em;
		}

		.user-device{
			@include font-size(16px, 28px);
			width: 90%;
			height: 15em;
			margin: 0 auto;
			margin-top: 2em;
			padding: 0.5em 1em;
			border: 2px solid var(--foregroundColor);

			h2{
				margin: 0;
				margin-bottom:0.5em;
			}
			h4{
				font-weight: 300;
			}
		}

		.btn-admin{
			width: 50%;
			margin: auto;
			margin-top: 2em;
			font-weight: 700;
		}

		#top {
			position: absolute;
			top: 0;
			height: 6vh;
			color: var(--backgroundColor);
		}

		.list-container {
			width: 100%;
			display: flex;
			justify-content: center;
			align-self: start;
			flex-wrap: wrap;
			// border: 1px solid red;
		}

		.btn-top {
			margin-top: 1em;
		}

		.pages-banner {
			position: absolute;
			display: flex;
			align-items: flex-end;
			// justify-content: center;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			padding: 1em;

			h3 {
				@include font-size(16px, 22px);
				font-family: 'Roboto', Times, serif;
				font-weight: 100;
				color: white;
			}
		}
	}
	footer{
		width: 100%;
		margin: 0;
	}
}

// FOR DESKTOPS
@include mQ(768px) {
	.pages-container {
		main{
			.user-device{
				width: 50%;
			}
		}
	}
}
