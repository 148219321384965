.icon {
	display: flex;
	cursor: pointer;

	svg {
		@include size(18px, 28px);
		fill: var(--foregroundColor);
		margin: auto;

		&:hover,
		:focus {
			fill: var(--mainColor);
		}
	}
}

.icon-back {
	svg {
		fill: var(--backgroundColor);
		margin: 0.5em auto;
	}
}

.icon-link {
	svg {
		@include size(16px, 28px);
		fill: var(--backgroundColor);
	}
}

.icon-share {
	display: flex;
	border: none;
	border-radius: 50%;
	background-color: transparent;
	cursor: pointer;

	svg {
		@include size(18px, 28px);
		fill: var(--greyColor);
		margin: auto;

		&:hover,
		:focus {
			fill: var(--mainColor);
		}
	}
}

.icon-share-footer {
	svg {
		fill: var(--lightColor);
	}
}

.icon-menu {
	display: flex;
	color: var(--foregroundColor);
	background-color: transparent;
	border: none;
	cursor: pointer;

	svg {
		@include size(18px, 28px);
		fill: var(--lightColor);
		margin: auto;
		transition: all 0.5s ease;

		&:hover {
			fill: var(--mainColor);
			// background: var(--backgroundColor);
		}
	}
}

.icon-close {
	position: absolute;
	height: 5vh;
	top: 0.5em;
	right: 0.5em;
	cursor: pointer;

	svg {
		@include size(22px, 28px);
		fill: var(--greyColor);
		margin: auto;

		&:hover {
			// fill: var(--mainColor);
			fill: var(--backgroundColor);
			background: var(--greyColor);
		}
	}
}

.icon-footer {
	display: flex;
	cursor: pointer;

	svg {
		@include size(24px, 34px);
		fill: var(--lightColor);
		margin: auto;

		&:hover,
		:focus {
			fill: var(--mainColor);
		}
	}
}

.icons-social {
	display: flex;
	cursor: pointer;
	svg {
		@include size(24px, 28px);
		fill: var(--greyColor);
		background: var(--backgroundColor);
		margin: auto;

		&:hover,
		&:focus {
			// fill: var(--foregroundColor);
			fill: var(--mainColor);
		}
	}
}

.icon-policy {
	display: flex;
	height: 5vh;
	position: absolute;
	top: 0.2em;
	right: 1em;

	svg {
		fill: var(--lightColor);
	}
}

.icon-instagram {
	svg {
		// fill: $Instagram;
		fill: var(--greyColor);
	}
}

.icon-twitter {
	svg {
		// fill: $Twitter;
		fill: var(--greyColor);
	}
}

.icon-linkedin {
	svg {
		// fill: $Linkedin;
		fill: var(--greyColor);
	}
}
