.elements_toast {
	position: relative;
	display: block;
	width: 80%;
	max-width: 350px;
	margin: auto;
	padding: 1.5em 1em;
	background-color: $Light;
	border-radius: 0.3em;
	border: 1px solid $ExtraLight;
	box-shadow: 3px 3px 6px $ExtraLight, -1px -1px 6px $ExtraLight;
	z-index: 20;

	h2 {
		display: block;
		text-align: center;
		color: $mainColor;
		margin-bottom: 1em;
	}

	p {
		display: block;
		text-align: center;
		margin: 0;
	}

	.toast-actions {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin-top: 1.6em;

		.delete {
			background-color: red;
		}
	}
}

.elements_toast.top {
	transform: translateY(-100%);
}

.elements_toast.botton {
	transform: translateY(100%);
}
