#modal {
	// position: absolute;
	// top: 0;
	// left: 50%;
	// transform: translateX(-50%);
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	max-width: $maxWidth;
	height: auto;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	background-color: rgba(grey, 0.8);
	overflow: hidden;
	// overflow-y: auto;
	z-index: 1000;
}
