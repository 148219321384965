.component_works-container {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	margin-bottom: 4em;
	overflow: hidden;
	// border: 1px solid red;

	.works-post {
		.works-post-container {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			padding: 0;
			color: var(--forgroundColor);
			background-color: var(--backgroundColor);
			box-shadow: var(--shadowSM);
			overflow: hidden;
			// border: 1px solid var(--background);

			.works-text {
				width: 100%;
				margin: auto;
				padding: 1em;
				overflow: hidden;
				// border: 1px solid red;

				.text {
					p {
						margin: 0.1em auto;
					}
					h2 {
						@include font-size(50px, 100px);
						margin: 0;
						margin-bottom: 0.3em;
						letter-spacing: 0.01em;
					}
					h3 {
						@include font-size(16px, 18px);
						margin: 0.8em 0 0.8em 0;
						font-weight: 300;
					}
					a {
						@include font-size(14px, 16px);
						display: block;
						// text-transform: uppercase;
						margin: 0.5em 0;
						color: var(--greyColor);
						text-decoration: none;
						font-weight: 300;
						letter-spacing: 0.04em;
						line-height: 1.2em;
						// text-align: center;
						cursor: pointer;

						&:hover,
						:focus {
							color: var(--mainColor);
							transform: scale(1.02);
						}
					}
				}
			}
			.works-img {
				display: flex;
				border: none;
				margin: 0;
				padding: 0;
				width: 100%;
				background-color: white;

				img {
					width: 100%;
					margin: auto;
					object-fit: cover;
					border: none;
					// border: 2px solid var(--backgroundColor);
					// box-shadow: var(--shadowSM);
				}
			}
		}
	}
}

// FOR DESKTOPS
@include mQ(768px) {
	.component_works-container {
		.works-post {
			width: 50%;
			padding: 1em;

			.works-post-container {
				height: 100%;

				.works-text {
					padding: 2em;
				}
			}
		}
	}
}
