.components_footer-container {
	@include font-size(16px, 22px);
	font-family: 'Roboto', sans-serif;
	margin-top: 3em;
	padding: 2em 0;
	background: var(--background);
	color: var(--lightColor);
	text-align: center;
	box-shadow: inset 0px -2px 3px rgba(255, 255, 255, 0.5),
		inset 0px 3px 3px rgba(255, 255, 255, 0.5);

	.components_footer {
		.modal {
			background-color: var(--background);
			height: 6em;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			margin: 0.5em 4em;
			border-radius: 1em;
			padding: 0.5em;
			border: 2px solid var(--lightColor);

			span,
			p {
				color: var(--lightColor);
			}
		}

		.modal.hidden {
			display: none;
		}

		p {
			@include font-size(14px, 21px);
			display: flex;
			justify-content: center;
			margin: 0.2em 0;
			letter-spacing: 0.5px;
			line-height: 1.2em;

			a {
				padding: 0;

				&:hover {
					transform: scale(1.1);
					// color: $mainLight;
					background-image: linear-gradient(
						45deg,
						var(--mainColor),
						var(--darkColor)
					);
					padding: 0 2em;
					border-radius: 5px;
				}
			}
		}
		a {
			color: var(--lightColor);
			text-decoration: none;
			cursor: pointer;
			padding: 0.8em;

			&:hover,
			:focus {
				color: var(--mainColor);
			}
		}

		.footer_icon-container {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 2em;
			padding: 0 0.2em;
			// border: 2px solid red;

			a,
			p,
			button {
				margin: auto 1em;
				padding: 0;
			}

			.blog {
				@include font-size(20px, 30px);
				padding: 0 0.6em;

				&:hover,
				:focus {
					transform: scale(1.2);
				}
			}

			.icon-footer{
				margin: 0 0.6em;
			}
			.text-footer {
				cursor: pointer;
				margin: 0 ;
				padding: 0;
				
				&:hover, :focus {
				color: var(--mainColor);
			}
			}
		}

		.copywrite {
			@include font-size(13px, 20px);
			padding: 0 0.2em;

			.linked {
				margin: 1em 2em;
			}
			.policy-text {
				padding: 0 0.6em;
			}

			p,
			p a {
				@include font-size(12px, 16px);
				font-weight: 100;
				margin-bottom: 0.5em;
				letter-spacing: 0.05em;
				line-height: 1.2em;
			}

			.policy {
				text-decoration: none;
				color: var(--lightColor);
				background-color: var(--background);
				border-radius: 3px;
				padding: 0 0.6em;
				letter-spacing: 0.05em;
				cursor: pointer;

				&:hover {
					// color: $mainLight;
					background-image: linear-gradient(
						45deg,
						var(--mainColor),
						var(--darkColor)
					);
					padding: 0 0.7em;
					border-radius: 5px;
				}
			}
		}
	}
}

// NAVBAR FOR TABLETS & DESKTOPS
@include mQ(768px) {
	.components_footer-container {
			.components_footer {
				.footer_icon-container {
					.icon-footer, .text-footer  {
						margin: 0 1em;
					}
				}
			}
		
	}
}
