.pages_nft-container {
	position: relative;
	width: 100%;
	max-width: $maxWidth;
	margin: auto;
	padding: 0;
	color: var(--foregroundColor);
	background: var(--backgroundColor);
	overflow-y: auto;

	main {
		position: relative;
		margin-top: 15vh;

		#top {
			position: absolute;
			top: 0;
			height: 6vh;
			color: var(--backgroundColor);
		}

		.btn-top {
			margin-top: 1em;
		}
	}
}
