.pages_videos-container {
	position: relative;
	width: 100%;
	max-width: $maxWidth;
	margin: auto;
	padding: 0;
	color: var(--foregroundColor);
	background: var(--backgroundColor);
	overflow-y: auto;

	main {
		position: relative;
		margin-top: 15vh;
		display: flex;
		flex-direction: column;

		h1{
			margin: auto;
			margin-bottom: 2em;
		}

		#top {
			position: absolute;
			top: 0;
			height: 6vh;
			color: var(--backgroundColor);
		}

		.btn-top {
			margin-top: 1em;
		}
		h3{
			color: var(--foregroundColor);
			margin-top: 1em;
			margin-bottom: 1em;
			text-align: center;
		}
		.player-wrapper{
			position: relative;
			padding-top: 56.25%;
			width: 95%;
			margin: auto;
			margin-bottom: 4em;
			pointer-events: none;
			border: 2px solid black;
		
			.react-player {
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		.player-wrapper-sound{
			position: relative;
			padding-top: 56.25%;
			width: 95%;
			margin: auto;
			margin-bottom: 4em;
			border: 2px solid black;
			
			.react-player {
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		
	}
	.page-links {
		@include font-size(20px, 28px);
		display: flex;
		width: 100%;
		padding: 0 0.5em;
		justify-content: space-between;
		align-items: center;
		margin-top: 2em;
		// gap: 1em;

		a {
			display: flex;
			min-width: 25vw;
			justify-content: center;

			svg {
				margin: 0;
			}
		}
	}
}

// FOR DESKTOPS
@include mQ(760px) {
	.pages_videos-container {
		main{
			padding-left: 3em;
			padding-right: 3em;
			
			.player-wrapper, .player-wrapper-sound{
				box-shadow: var(--shadowSM);
			}
		}
	}
}