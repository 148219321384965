.elements_card-container {
	position: relative;
	width: 98vw;
	max-width: 330px;
	padding: 1em;
	margin: 0.5em;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);

	li {
		width: 100%;
		display: flex;

		p {
			font-size: 20px;
			line-height: 30px;

			&.card-id {
				font-size: 18px;
				color: $MedLight;
			}

			&.created {
				font-size: 16px;
				color: $MedLight;
			}
		}

		a.wiki {
			font-size: 18px;
			line-height: 30px;
			color: blue;
			text-decoration: none;
		}

		.tag {
			margin-right: 0.5em;
			color: $MedLight;
			font-size: 1em;
		}
	}
	.icons-container {
		margin-top: 1em;
	}
}
