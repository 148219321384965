* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Roboto', sans-serif;
	background-color: var(--background);

	header {
		@include font-size(20px, 28px);
		position: fixed;
		display: flex;
		width: 100%;
		max-width: $maxWidth;
		height: 8vh;
		padding: 0 1em;
		color: var(--lightColor);
		// background-color: black;
		justify-content: space-between;
		align-items: center;
		z-index: 10;
		// border: 2px solid red;

		a.logo {
			@include font-size(22px, 28px);
			// font-family: 'Pacifico', cursive;
			font-family: 'Oooh Baby', cursive;
			font-weight: 300;
			text-decoration: none;
			color: var(--lightColor);
			cursor: pointer;
			transition: color 0.5s ease;

			&:hover {
				color: var(--mainColor);
			}
		}

		.icon {
			height: 5vh;
			color: var(--foregroundColor);
			cursor: pointer;

			svg {
				@include size(18px, 28px);
				fill: var(--lightColor);
				margin: auto;
				transition: all 0.5s ease;

				&:hover {
					fill: var(--mainColor);
					// background: var(--backgroundColor);
				}
			}
		}
	}

	span {
		h2 {
			@include font-size(30px, 48px);
			font-weight: 900;
			text-align: center;
			// margin: 1em;
			margin-top: 0.5em;
			margin-bottom: 0.5em;
			letter-spacing: 0.08em;
			line-height: 1.2em;
		}
	}

	.intro {
		position: relative;
		min-height: 90vh;
		margin: 1em;
		padding: 1em;
		text-align: center;
		color: var(--forgroundColor);

		h1 {
			@include font-size(20px, 28px);
			font-weight: 500;
			margin-top: 1.5em;
			letter-spacing: 0.05em;
			line-height: 1em;
		}
		h2 {
			@include font-size(16px, 24px);
			font-weight: 300;
			margin-top: 1em;
			letter-spacing: 0.05em;
			line-height: 1.1em;
			font-style: italic;
		}
		h3 {
			@include font-size(16px, 24px);
			font-weight: 300;
			margin-top: 0.9em;
			letter-spacing: 0.05em;
			line-height: 1.2em;
		}
	}

	h2 {
		@include font-size(30px, 48px);
		text-align: center;
		margin: 1em;
		margin-top: 2em;
		margin-bottom: 0.5em;
		letter-spacing: 0.08em;
		line-height: 1.2em;
	}

	h3 {
		@include font-size(15px, 22px);
		font-weight: 100;
		letter-spacing: 0.08em;
		line-height: 0.8em;
	}

	p {
		@include font-size(14px, 16px);
		color: var(--forgroundColor);
		margin: 0.8em;
		font-weight: 300;
		letter-spacing: 0.05em;
		line-height: 1.2em;
		// text-align: justify;
		// text-indent: 1.5em;
		// word-spacing: normal;
	}
}
