// RESPONSIVE (VIEWPORT SIZES)
$minWidth: 350px !default;
$maxWidth: 1600px !default;

// Colors:
$mainColor: rgb(0, 122, 255); // Apple System Light Blue
$mainDark: rgb(20, 100, 255);
$mainColorhover: rgb(0, 100, 200);
$Light: rgb(247, 247, 247); // My White
$backColor: rgb(230, 230, 230); // Background color
$Dark: rgb(28, 28, 30); // My Black
$MedLight: rgb(142, 142, 147); // My Gray: Silver
$Danger: rgb(255, 59, 48); // Apple System Light Red
$ExtraLight: rgba(1, 1, 1, 0.1);

// Social Media
$Amazon: #ff9900;
$Facebook: #3b5998;
$Github: #000;
$Instagram: #dd2a7b;
$Linkedin: #0077b5;
$Telegram: #0088cc;
$Twitter: #1da1f2;
$Whatsapp: #00bb2d;
$Print: 'red';

// COLORS HSL(Hue, Saturation, Lightness)
:root {
	--color: 287;
	--saturation: 100%;
	--mainColor: hsl(var(--color), var(--saturation), 60%);
	--darkColor: hsl(var(--color), var(--saturation), 5%); // My Black
	--background: hsl(0, 0%, 0%); // Black
	--lightColor: hsl(var(--color), var(--saturation), 99%); // My White
	--alertColor: hsl(35, var(--saturation), 50%); // Apple System Light Orange
	--dangerColor: hsl(0, var(--saturation), 65%);
	--successColor: hsl(120, 80%, 40%);

	// COLORS LIGHT MODE
	--greyColor: hsl(0, 0%, 25%); // Dark Grey
	--backgroundColor: hsl(var(--color), var(--saturation), 99%); // My White
	--foregroundColor: hsl(var(--color), var(--saturation), 5%); // My Black
	--shadow: 3px 3px 6px rgba(0, 0, 0, 0.5), -1px 3px 6px rgba(0, 0, 0, 0.5);
	--shadowSM: 1px 1px 3px rgba(0, 0, 0, 0.5), -1px 1px 3px rgba(0, 0, 0, 0.5);
}

// COLORS DARK MODE
@media (prefers-color-scheme: dark) {
	:root {
		--greyColor: hsl(0, 0%, 75%); // Light Grey
		--backgroundColor: hsl(var(--color), var(--saturation), 5%); // My Black
		--foregroundColor: hsl(var(--color), var(--saturation), 99%); // My White
		--shadow: 3px 3px 6px rgba(250, 250, 250, 0.3),
			-1px 3px 6px rgba(250, 250, 250, 0.3);
		--shadowSM: 1px 1px 3px rgba(250, 250, 250, 0.3),
			-1px 1px 3px rgba(250, 250, 250, 0.3);
	}
}
