.policy {
	background: var(--backgroundColor);
	color: var(--foregroundColor);
	width: 100vw;
	overflow: hidden;

	header {
		@include font-size(14px, 18px);
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 9vh;
		color: var(--lightColor);
		background-color: var(--background);
		text-align: center;
		line-height: 5vh;
		z-index: 10;

		h1.policy-title {
			color: var(--lightColor);
			display: flex;
			justify-content: center;
			margin-top: 0.5em;
		}
	}

	#top {
		position: absolute;
		top: 0;
		height: 6vh;
		color: var(--lightColor);
	}

	main {
		display: block;
		width: 100%;
		min-height: 100vh;
		padding: 1em;
		margin-top: 10vh;
		color: var(--foregroundColor);
		background-color: var(--backgroundColor);
		// overflow: hidden;
		// overflow: auto;

		p {
			text-align: justify;
			margin-bottom: 3rem;
		}
	}

	footer.policy_footer {
		@include font-size(12px, 15px);
		position: relative;
		display: flex;
		width: 100%;
		height: 6vh;
		color: var(--lightColor);
		background-color: var(--background);
		a {
			margin: auto;
			text-decoration: none;
			color: var(--lightColor);
		}
	}
}
